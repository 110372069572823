import debounce from "lodash/debounce";
import { useCallback, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useScreenWidth from "../../../../../../../hooks/useScreenwidth";
import { getProductsData } from "../../../../../../../services/productsService";
import { setLoading } from "../../../../../../../store/slices/loaderSlice";

import Search from "../../../../../../atoms/Search";
import style from "../../../../Investors/NewInvestorDatabase/Tabs/Company/styles.module.scss";

import NoContentCard from "../../../../../../molecules/NoContentCard";
import ContentSharingPhoneShimmer from "../../../../ContentSharing/Shimmer/ContentSharingPhones";
import ShimmerDatabase from "../../../Shimmer/shimmerDatabase";

import moment from "moment";
import ImageViewer from "../../../../../../atoms/ImageViewer";
import TypeDiv from "../../../../../../atoms/TypeDiv";
import Pagination from "../../../../../../molecules/Pagination";
import SaveComponent from "../../../../../../molecules/SaveComponent";

import { getData } from "../../../../../../../services";
import { useSubscription } from "../../../../../../../SubscriptionContext";
import ResetBtn from "../../../../../../atoms/ResetBtn/ResetBtn";
import ArrowDown from "../../../../../../Icons/ArrowDown";
import SaveAll from "../../../../../../molecules/SaveComponent/SaveAll";
import MultiselectFilter from "../../../../../../organisms/MultiselectFilter";
import SortFunctionality from "../../../../../../organisms/SortFunctionality";
import CreatedInvestorFilter from "../../../../Investors/Filter/CreatedInvestorFilter";
import UpdateInvestorFilter from "../../../../Investors/Filter/UpdatedInvestorFilter";
import DireactDeal from "../../NewFilter/DirectDeal";
import Funds from "../../NewFilter/Funds";
import Location from "../../NewFilter/Location";

import { useCreditCount } from "../../../../../../../contexts/CreditCountContext";

const Company = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.peopleData?.peopleData);
  const filters_accessible = !userInfo?.walkin_trial || userInfo?.filter_access || userInfo?.role !== 'Manager/Sponsor';
  const is_walkin = userInfo?.walkin_trial;

  const [cookies] = useCookies(["t"]);
  const [showLoader, setShowLoader] = useState(false);
  const [filtersInitialized, setFiltersInitialized] = useState(false);
  const hasMounted = useRef(false);

  const { isSubscriptionExpired, handleUserClick } = useSubscription();
  const { updateCreditInfo } = useCreditCount();

  const [searchvalue, setSearchvalue] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sort_by, setSort_By] = useState(null);
  const [refetch, setRefetch] = useState(false);

  const [aum, setAum] = useState({ min: null, max: null });
  const [fundingGoal, setFundingGoal] = useState({ min: null, max: null });
  const [resetAll, setResetAll] = useState(false);
  const [tagData, setTagData] = useState([]);
  const [tagFilter, setTagFilter] = useState();
  const [createdFilter, setCreatedFilter] = useState();
  const [updatedFilter, setUpdatedFilter] = useState();
  const [filterOpenTags, setFilterOpenTags] = useState(false);
  const [filterOpenCreated, setFilterOpenCreated] = useState(false);
  const [filterOpenUpdated, setFilterOpenUpdated] = useState(false);
  const [saved_ids, set_saved_ids] = useState([]);

  const [filters, setFilters] = useState({});
  const [locationFilter, setLocationFilter] = useState({});
  const [updatedTag, setUpdatedTag] = useState({});
  const [direactDealFilter, setDireactDealsFilter] = useState({});

  const [productList, setProductList] = useState([]);

  const screenwidth = useScreenWidth();
  const location = useLocation();

  const [quickSearchData, setQuickSearchData] = useState(location?.state);

  useEffect(() => {
    if (location?.state?.filterdata) {
      const filterdata = location?.state?.filterdata;
      setSort_By(filterdata?.sort_by);
      setDireactDealsFilter(filterdata?.direactDealFilter);
      setFilters(filterdata?.filters);
      setTagFilter(filterdata?.tagFilter);
      setCreatedFilter(filterdata?.createdFilter);
      setUpdatedFilter(filterdata?.updatedFilter);
      setAum(filterdata?.aum);
      setFundingGoal(filterdata?.fundingGoal);
      setLocationFilter(filterdata?.locationFilter);
      setQuickSearchData(filterdata?.quickSearchData);
      setCurrentPage(filterdata?.currentPage);
      setItemsPerPage(filterdata?.itemsPerPage);
    }

    setFiltersInitialized(true);
  }, [location]);

  const navigate = useNavigate();
  const [dropdownPositions, setDropdownPositions] = useState({
    tags: {},
    created: {},
    updated: {},
  });

  // Getting Data

  const handleView = (e, item) => {
    const stateData = {
      previousPage:
        location.pathname.endsWith("/company") ||
        location.pathname.endsWith("/Company")
          ? location.pathname
          : `${location.pathname}/company`,
      previousName: "Fund Directory",
      filters: {
        filters,
        sort_by,
        aum,
        direactDealFilter,
        tagFilter,
        createdFilter,
        updatedFilter,
        fundingGoal,
        locationFilter,
        quickSearchData,
        currentPage,
        itemsPerPage,
      },
    };

    if (userInfo?.walkin_trial && userInfo?.role == 'Manager/Sponsor') {
      updateCreditInfo({ type: "company", id: item, state: stateData });
    } else {
      navigate(`/dashboard/company/${item}`, { state: stateData });
      // window.open(`/dashboard/company/${item}`, "_blank");
    }
  };

  const fetchTags = async () => {
    const res = await getData({
      endpoint: "Search/getTags",
      params: {
        type: "Manager/Sponsor",
      },
      token: cookies.t,
    });
    let result = [];
    res.forEach((item) => {
      if (item !== null) {
        result.push({ value: item, label: item });
      }
    });
    setTagData(result);
  };

  useEffect(() => {
    fetchTags();
  }, []);

  const handleDirectType = (direactDealFilter) => {
    const tempDirect =
      direactDealFilter["Direct Deal Type"]?.map((item) => item?.value) || [];

    return [...tempDirect];
  };

  const handleDirectStrategy = (direactDealFilter) => {
    const tempSPV =
      direactDealFilter["Direct Deal Strategy"]?.map((item) => item?.value) ||
      [];

    return [...tempSPV];
  };

  useEffect(() => {
    if (quickSearchData?.value) {
      const tempData = {
        "Product Type": Array.isArray(quickSearchData?.value)
          ? quickSearchData?.data
          : [quickSearchData],
      };

      setFilters(tempData);
    }
  }, [quickSearchData, location.state, navigate]);

  const fetchProductData = async () => {
    try {
      dispatch(setLoading(true));

      const res = await getProductsData({
        token: cookies.t,
        search: searchvalue,

        currentPage: !filters_accessible ? 1 : currentPage,
        itemsPerPage: !filters_accessible ? 10 : itemsPerPage,

        ...(filters_accessible && {
          sort_by,
          aum,
          structureData: filters?.["Product Structure"]?.map(
            (proCategory) => proCategory?.value
          ),
          product_type:
            direactDealFilter["Direct Deal Type"] &&
            filters?.["Product Type"]?.length > 0
              ? [
                  ...handleDirectType(direactDealFilter),
                  ...filters["Product Type"].map((category) => category?.value),
                ]
              : direactDealFilter["Direct Deal Type"]
              ? handleDirectType(direactDealFilter)
              : filters?.["Product Type"]?.map((category) => category?.value),

          product_strategy:
            direactDealFilter["Direct Deal Strategy"] &&
            filters?.["Product Strategy"]?.length > 0
              ? [
                  ...handleDirectStrategy(direactDealFilter),
                  ...filters["Product Strategy"].map(
                    (category) => category?.value
                  ),
                ]
              : direactDealFilter["Direct Deal Strategy"]
              ? handleDirectStrategy(direactDealFilter)
              : filters?.["Product Strategy"]?.map(
                  (category) => category?.value
                ),

          country: locationFilter?.Country?.map((country) => country.label),
          state: locationFilter?.State?.map((state) => state.label),
          city: locationFilter?.City?.map((city) => city.label),
          sector: filters?.Sector?.map((sector) => sector.value),
          geography: filters?.Geography?.map((geography) => geography.value),
          fundingFilter: fundingGoal,
          tags: tagFilter,
          after_date: updatedTag?.startDate0,
          before_date: updatedTag?.endDate0,
          created_start_date: createdFilter?.created_start_date,
          created_end_date: createdFilter?.created_end_date,
          updated_start_date: updatedFilter?.updated_start_date,
          updated_end_date: updatedFilter?.updated_end_date,
        }),
      });

      if (res) {
        setShowLoader(true);
      }
      setProductList(res);
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    if (!filtersInitialized) return;

    if (!hasMounted.current) {
      hasMounted.current = true; // Mark as mounted
    }

    // if (refetch) {
    fetchProductData();
    // }
  }, [
    hasMounted,
    filtersInitialized,
    searchvalue,
    currentPage,
    itemsPerPage,
    cookies.t,
    refetch,
    ...(filters_accessible
      ? [
          aum,
          fundingGoal,
          direactDealFilter,
          filters,
          updatedTag,
          locationFilter,
          tagFilter,
          createdFilter,
          updatedFilter,
          sort_by,
        ]
      : []),
  ]);
  // Pagination --------------------
  const totalItems = productList?.total_record;

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);
  // ---------------------------------

  // Search --------------------------
  const handleSearch = (e) => {
    setSearchvalue(e);
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  };

  const handleDebounceSearch = debounce(handleSearch, 1000);

  // -------------------------

  const handleResetAll = () => {
    setAum(() => ({
      min: null,
      max: null,
    }));
    setResetAll(!resetAll);
  };

  const updateDropdownPositions = () => {
    const tagsDropdown = document.getElementById("tagsDropdown");
    const tagsDropdownRect = tagsDropdown?.getBoundingClientRect();

    const createdDropdown = document.getElementById("createdDropdown");
    const createdDropdownRect = createdDropdown?.getBoundingClientRect();

    const updatedDropdown = document.getElementById("updatedDropdown");
    const updatedDropdownnRect = updatedDropdown?.getBoundingClientRect();

    setDropdownPositions({
      tags: {
        left: tagsDropdownRect?.left,
      },
      created: {
        left: createdDropdownRect?.left,
      },
      updated: {
        left: updatedDropdownnRect?.left,
      },
    });
  };

  useEffect(() => {
    updateDropdownPositions();
  }, [screenwidth]);

  const shouldShowSaveAll = () => {
    const hasFilters = filters && Object.keys(filters).length > 0;

    const hasLocationFilter =
      locationFilter && Object.keys(locationFilter).length > 0;

    const hasTagFilter = tagFilter && tagFilter.length > 0;

    const hasCreatedFilter =
      createdFilter &&
      typeof createdFilter === "object" &&
      !Array.isArray(createdFilter) &&
      Object.keys(createdFilter).length > 0;

    const hasUpdatedFilter =
      updatedFilter &&
      typeof updatedFilter === "object" &&
      !Array.isArray(updatedFilter) &&
      Object.keys(updatedFilter).length > 0;

    const hasAum = aum && (aum.min || aum.max);

    const hasDirectDeal =
      direactDealFilter && Object.keys(direactDealFilter).length > 0;

    const hasFundingGoal = fundingGoal && (fundingGoal.min || fundingGoal.max);

    // Final condition check
    const result =
      hasFilters ||
      searchvalue ||
      hasLocationFilter ||
      hasTagFilter ||
      hasCreatedFilter ||
      hasUpdatedFilter ||
      hasAum ||
      hasFundingGoal ||
      hasDirectDeal;

    return result;
  };

  return (
    <div className={style.mainCompanyDiv}>
      <div className="d-flex justify-content-between">
        <div className="h1-i">Companies</div>
        <Search variant="search_md" onSearchEmit={handleDebounceSearch} />
      </div>
      {/* className={style.companiesHeader} */}
      <div className={`${style.companiesHeader}`}>
        <div
          className={`d-flex gap-2 flex-wrap mt-3 ${
            !filters_accessible ? "filters-not-allowed" : ""
          }`}
        >
          <SortFunctionality
            reset={resetAll}
            handleSort={(sort) => {
              setSort_By(sort);
            }}
            selected={sort_by}
          />

          <Funds
            onFilterChange={(values) => {
              setFilters(values);
            }}
            type="product"
            headingtext="Funds"
            headingMobile={false} // Set to true if you want to hide the "Filters" button text on mobile
            disableKeysUntilCondition={true} // Pass the disableKeysUntilCondition object
            reset={resetAll}
            id="more_Filters"
            isDateRange
            dateRangeCount={1}
            dateRangeNames={["AUM($mm)"]}
            handleAum={(data) => {
              setAum(data);
            }}
            QuickSearch={quickSearchData}
            setQuickSearchData={setQuickSearchData}
            selectedFilters={filters}
            selectedAum={aum}
            // className={style.reset_responsive_Mobile_btn}
          />
          <DireactDeal
            type="product"
            headingtext="Direct Deals"
            id="direact_deals"
            headingMobile={false} // Set to true if you want to hide the "Filters" button text on mobile
            disableKeysUntilCondition={true}
            isDateRange
            dateRangeCount={1}
            dateRangeNames={["Funding Goal($mm)"]}
            onFilterChange={(values) => {
              setDireactDealsFilter(values);
            }}
            handleAum={(data) => {
              setFundingGoal(data);
            }}
            selectedFilters={direactDealFilter}
            selectedAum={fundingGoal}
            reset={resetAll}
          />
          <Location
            onFilterChange={(values) => {
              setLocationFilter(values);
            }}
            type="product"
            headingtext="Location"
            headingMobile={false}
            disableKeysUntilCondition={true}
            reset={resetAll}
            id={"location_filter"}
            selectedFilters={locationFilter}
          />
          <CreatedInvestorFilter
            headerText="Created Date"
            handleFilter={(data) => setCreatedFilter(data)}
            filterOpenCreated={filterOpenCreated}
            setFilterOpenCreated={setFilterOpenCreated}
            id="createdDropdown"
            dropdownPosition={dropdownPositions.created}
            type="small"
            reset={resetAll}
            selectedData={createdFilter}
          />
          <UpdateInvestorFilter
            handleFilter={(data) => setUpdatedFilter(data)}
            filterOpenUpdated={filterOpenUpdated}
            setFilterOpenUpdated={setFilterOpenUpdated}
            id="updatedDropdown"
            dropdownPosition={dropdownPositions.updated}
            type="small"
            reset={resetAll}
            selectedData={updatedFilter}
          />

          <MultiselectFilter
            title="Tags"
            icon={<ArrowDown />}
            data={tagData}
            handleFilter={(filter) => {
              setTagFilter(filter);
              setResetAll(false);
            }}
            open={filterOpenTags}
            setOpen={setFilterOpenTags}
            setQuickSearchData={setQuickSearchData}
            id="tagsDropdown"
            dropdownPosition={dropdownPositions.tags}
            sizeType="small"
            reset={resetAll}
            tabresponsive
            info={true}
            selectedData={tagFilter}
            information={"Based on added Tags"}
          />

          <ResetBtn onReset={() => handleResetAll()} type="small" />

          {shouldShowSaveAll() && productList?.data?.length > 0 && !is_walkin && (
            <SaveAll
              type={"Company"}
              saveType={"ManagerCompany"}
              filters={filters}
              search={searchvalue}
              setRefetch={setRefetch}
              locationFilter={locationFilter}
              tagFilter={tagFilter}
              createdFilter={createdFilter}
              updatedFilter={updatedFilter}
              aum={aum}
              directDealTypeFilter={handleDirectType(direactDealFilter)}
              directDealStrategyFilter={handleDirectStrategy(direactDealFilter)}
              fundingGoal={fundingGoal}
            />
          )}
        </div>

        {/* <div className="filter-info-icon">
          <div
            data-title={`Filters are not accessible`}
            className="tooltip-container"
          >
            <Info size="20px"/>
          </div>
        </div> */}
      </div>

      {!showLoader ? (
        <>
          {screenwidth > 900 ? (
            <ShimmerDatabase />
          ) : (
            <ContentSharingPhoneShimmer type="Database" />
          )}
        </>
      ) : (
        <>
          <div className={style.investorMapWrap}>
            {productList?.data?.length > 0 ? (
              <>
                <>
                  <>
                    <>
                      {productList?.data
                        ?.concat(
                          !userInfo?.manager_database &&
                            productList?.total_record > 10
                            ? [
                                productList?.data?.[
                                  productList?.data?.length - 1
                                ],
                              ]
                            : []
                        )
                        .map((item, index) => {
                          const isLastItem =
                            index === productList?.data?.length;

                          return (
                            <div
                              className={`${style.investorDataWrap} ${
                                isLastItem && !userInfo?.manager_database
                                  ? style.blurredItemContainer
                                  : ""
                              }`}
                              key={index}
                            >
                              {isLastItem && (
                                <div className={style.subscriptionOverlay}>
                                  <div className={style.subscriptionMessage}>
                                    You have restricted access to this module.{" "}
                                    <span
                                      className="text-decoration-underline cursor-pointer"
                                      onClick={(e) => handleUserClick(e)}
                                    >
                                      Click here
                                    </span>{" "}
                                    to view more information.
                                  </div>
                                </div>
                              )}
                              <div
                                className={`${style.investor_inner_datawrap} ${
                                  isLastItem && !userInfo?.manager_database
                                    ? style.blur
                                    : ""
                                }`}
                              >
                                <div className={style.investorInfoWrap}>
                                  <ImageViewer
                                    type="Company"
                                    imageUrl={item.profile_photo}
                                  />
                                  <div>
                                    <div
                                      className={`d-flex align-items-center gap-3 mb-1 ${style.Product_database_heading_container}`}
                                    >
                                      <h6
                                        className={style.companyName}
                                        onClick={(e) => {
                                          isSubscriptionExpired
                                            ? handleUserClick(e)
                                            : handleView(e, item?.company_id);
                                        }}
                                      >
                                        {item?.name}
                                      </h6>
                                      <TypeDiv
                                        classes={style.mbres_none}
                                        data={item?.type}
                                        color={
                                          item?.type ===
                                          "Investor/Manager/Sponsor"
                                            ? "#B5E4CA"
                                            : item?.type === "Investor"
                                            ? "#FFBC99"
                                            : "#CABDFF"
                                        }
                                      />
                                    </div>
                                    {item?.address?.length > 0 && (
                                      <div
                                        className={`${style.companyType} ${style.mbres_none}`}
                                      >
                                        {item?.address?.[0]?.city +
                                          ", " +
                                          item?.address?.[0]?.state_region +
                                          ", " +
                                          item?.address?.[0]?.country}
                                      </div>
                                    )}
                                    <div
                                      className={`${style.companyType} ${style.mbres_none}`}
                                    >
                                      <div className="d-flex align-items-center">
                                        Added On :{" "}
                                        {moment
                                          .utc(item?.created_date)
                                          .format("MMM D, YYYY")}
                                        <div className="dot"></div>
                                        Last Update:{" "}
                                        {moment
                                          .utc(item?.updated_date)
                                          .format("MMM D, YYYY")}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`${style.investorButtons} ${
                                  isLastItem && isSubscriptionExpired
                                    ? style.blur
                                    : ""
                                }`}
                              >
                                {!userInfo?.walkin_trial && (
                                  <SaveComponent
                                    type="Company"
                                    key={item?.company_id}
                                    id={item.company_id}
                                    saved_id={item.saved_list}
                                    btnType="btn-outline-dark"
                                  />
                                )}
                                <div
                                  className="btn btn-outline-dark"
                                  onClick={(e) => {
                                    isSubscriptionExpired
                                      ? handleUserClick(e)
                                      : handleView(e, item?.company_id);
                                  }}
                                >
                                  View
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </>
                  </>
                </>
                {/* {isSubscriptionExpired && productList?.data?.length > 0 && (
                  <div
                    className={`${style.investorDataWrap} ${style.blurredItem}`}
                  >
                    <div className={style.subscriptionMessage}>
                      You have restricted access to this module
                    </div>
                    <div className={style.investor_inner_datawrap}>
                      <div className={style.investorInfoWrap}>
                        <ImageViewer
                          type="Company"
                          imageUrl={
                            productList.data[productList.data.length - 1]
                              .profile_photo
                          }
                        />
                        <div
                          className={`d-flex align-items-center gap-3 mb-1 ${style.Product_database_heading_container}`}
                        >
                          <h6 className={style.companyName}>
                            {productList.data[productList.data.length - 1].name}
                          </h6>
                          <TypeDiv
                            classes={style.mbres_none}
                            data={
                              productList.data[productList.data.length - 1]
                                ?.type
                            }
                            color={
                              productList.data[productList.data.length - 1]
                                ?.type === "Investor/Manager/Sponsor"
                                ? "#B5E4CA"
                                : productList.data[productList.data.length - 1]
                                    ?.type === "Investor"
                                ? "#FFBC99"
                                : "#CABDFF"
                            }
                          />
                        </div>
                        {productList.data[productList.data.length - 1]?.address
                          ?.length > 0 && (
                          <div
                            className={`${style.companyType} ${style.mbres_none}`}
                          >
                            {productList.data[productList.data.length - 1]
                              ?.address?.[0]?.city +
                              ", " +
                              productList.data[productList.data.length - 1]
                                ?.address?.[0]?.state_region +
                              ", " +
                              productList.data[productList.data.length - 1]
                                ?.address?.[0]?.country}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )} */}
              </>
            ) : (
              <>
                <NoContentCard title="No Data Found" />
              </>
            )}
          </div>

          {productList?.total_record >= 10 && !isSubscriptionExpired && (
            <div className="mt-2 ms-sm-4">
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={totalItems}
                currentPage={currentPage}
                onPageChange={handlePageChange}
                onItemsPerPageChange={handleItemsPerPageChange}
                disabled={!filters_accessible}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Company;
