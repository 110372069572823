import { Box } from "@mui/material";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useSubscription } from "../../../../../../../SubscriptionContext";
import Default from "../../../../../../../assets/images/default.png";
import Default1 from "../../../../../../../assets/images/default2.png";
import { areObjectsEqual } from "../../../../../../../helpers/areObjectsEqual";
import useScreenWidth from "../../../../../../../hooks/useScreenwidth";
import { getData } from "../../../../../../../services";
import { getProductsData } from "../../../../../../../services/productsService";
import ArrowDown from "../../../../../../Icons/ArrowDown";
import CloseButton from "../../../../../../atoms/CloseButton";
import GoogleMapCustom from "../../../../../../atoms/GoogleMap";
import ResetBtn from "../../../../../../atoms/ResetBtn/ResetBtn";
import Search from "../../../../../../atoms/Search";
import TypeDiv from "../../../../../../atoms/TypeDiv";
import NoContentCard from "../../../../../../molecules/NoContentCard";
import Pagination from "../../../../../../molecules/Pagination";
import TabStrip from "../../../../../../molecules/TabStrip";
import MultiselectFilter from "../../../../../../organisms/MultiselectFilter";
import EditBlock from "../../../../../../templates/BlockEdit";
import CreatedInvestorFilter from "../../../../Investors/Filter/CreatedInvestorFilter";
import UpdateInvestorFilter from "../../../../Investors/Filter/UpdatedInvestorFilter";
import style from "../../../../Investors/NewInvestorDatabase/Tabs/Roadshow/styles.module.scss";
import OverView from "../../../RoadShow/OverView";
import Product from "../../../RoadShow/Product";
import DireactDeal from "../../NewFilter/DirectDeal";
import Funds from "../../NewFilter/Funds";
import Location from "../../NewFilter/Location";

import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useCreditCount } from "../../../../../../../contexts/CreditCountContext";

const Roadshow = () => {
  const [cookies] = useCookies(["t"]);
  const location = useLocation();
  const navigate = useNavigate();
  const hasMounted = useRef(false);

  useEffect(() => {
    if (location?.state?.filterdata) {
      const filterdata = location?.state?.filterdata;
      setDireactDealsFilter(filterdata?.direactDealFilter);
      setFilters(filterdata?.filters);
      setTagFilter(filterdata?.tagFilter);
      setCreatedFilter(filterdata?.createdFilter);
      setUpdatedFilter(filterdata?.updatedFilter);
      setAum(filterdata?.aum);
      setFundingGoal(filterdata?.fundingGoal);
      setLocationFilter(filterdata?.locationFilter);
      setCurrentPage(filterdata?.currentPage)
      setItemsPerPage(filterdata?.itemsPerPage)
    }
  }, [location]);

  const { isSubscriptionExpired, handleUserClick } = useSubscription();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchvalue, setSearchvalue] = useState();
  const [productCompanyList, setProductCompanyList] = useState([]);
  const [productCompanyId, setProductCompanyId] = useState();
  const [productAddressData, setProductAddressData] = useState();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("overview");
  const [markerlatlan, setmarkerlatlan] = useState();

  const [isDataThere, setIsDataThere] = useState(true);
  const [filters, setFilters] = useState({});
  const [locationData, setLocationData] = useState([]);

  const [aum, setAum] = useState({ min: null, max: null });
  const [fundingGoal, setFundingGoal] = useState({ min: null, max: null });
  const [direactDealFilter, setDireactDealsFilter] = useState({});
  const [locationFilter, setLocationFilter] = useState({});
  const [createdFilter, setCreatedFilter] = useState();
  const [updatedFilter, setUpdatedFilter] = useState();
  const [tagFilter, setTagFilter] = useState();
  const [tagData, setTagData] = useState([]);
  const [filterOpenTags, setFilterOpenTags] = useState(false);
  const [filterOpenCreated, setFilterOpenCreated] = useState(false);
  const [filterOpenUpdated, setFilterOpenUpdated] = useState(false);
  const [resetAll, setResetAll] = useState(false);
  const screenWidth = useScreenWidth();

  const userInfo = useSelector((state) => state.peopleData?.peopleData);
  const filters_accessible = !userInfo?.walkin_trial || userInfo?.filter_access || userInfo?.role !== 'Manager/Sponsor';
  const is_walkin = userInfo?.walkin_trial;
  const { updateCreditInfo } = useCreditCount();

  const handleDirectType = (direactDealFilter) => {
    const tempDirect =
      direactDealFilter["Direct Deal Type"]?.map((item) => item?.value) || [];

    return [...tempDirect];
  };

  const handleDirectStrategy = (direactDealFilter) => {
    const tempSPV =
      direactDealFilter["Direct Deal Strategy"]?.map((item) => item?.value) ||
      [];

    return [...tempSPV];
  };

  const getProductList = async () => {
    try {
      const res = await getProductsData({
        type: "roadshow",
        token: cookies.t,
        search: searchvalue,
        currentPage : !filters_accessible ?  1 : currentPage,
        itemsPerPage : !filters_accessible ? 10 : itemsPerPage,
        ...(filters_accessible && {
          aum,
          categoryData: filters?.["Product Category"]?.map(
            (proCategory) => proCategory?.value
          ),
          structureData: filters?.["Product Structure"]?.map(
            (proCategory) => proCategory?.value
          ),
          product_type:
            direactDealFilter["Direct Deal Type"] &&
            filters?.["Product Type"]?.length > 0
              ? [
                  ...handleDirectType(direactDealFilter),
                  ...filters["Product Type"].map((category) => category?.value),
                ]
              : direactDealFilter["Direct Deal Type"]
              ? handleDirectType(direactDealFilter)
              : filters?.["Product Type"]?.map((category) => category?.value),
          product_strategy:
            direactDealFilter["Direct Deal Strategy"] &&
            filters?.["Product Strategy"]?.length > 0
              ? [
                  ...handleDirectStrategy(direactDealFilter),
                  ...filters["Product Strategy"].map(
                    (category) => category?.value
                  ),
                ]
              : direactDealFilter["Direct Deal Strategy"]
              ? handleDirectStrategy(direactDealFilter)
              : filters?.["Product Strategy"]?.map(
                  (category) => category?.value
                ),
          country: locationFilter?.Country?.map((country) => country.label),
          state: locationFilter?.State?.map((state) => state.label),
          city: locationFilter?.City?.map((city) => city.label),
          sector: filters?.Sector?.map((sector) => sector.value),
          geography: filters?.Geography?.map((geography) => geography.value),
          fundingFilter: fundingGoal,
          tags: tagFilter,
          created_start_date: createdFilter?.created_start_date,
          created_end_date: createdFilter?.created_end_date,
          updated_start_date: updatedFilter?.updated_start_date,
          updated_end_date: updatedFilter?.updated_end_date,
        }),
      });

      setProductCompanyList(res);
      setProductAddressData(res?.data);
      if (res?.data?.length === 0) {
        setIsModalOpen(false);
        setIsDataThere(false);
      }
    } catch (exp) {
      console.log("InvestorList", exp);
    }
  };

  // getting data ------------------------
  useEffect(() => {
    if (location.state && !hasMounted.current) {
      hasMounted.current = true; // Mark as mounted
      return;
    }

    getProductList();
  }, [
    searchvalue,
    itemsPerPage,
    currentPage,
    cookies.t,
    ...(filters_accessible
      ? [
          aum,
          fundingGoal,
          direactDealFilter,
          filters,
          locationFilter,
          tagFilter,
          createdFilter,
          updatedFilter,
        ]
      : []),
  ]);

  // -------------------------------------

  const fetchTags = async () => {
    const res = await getData({
      endpoint: "Search/getTags",
      params: {
        type: "Manager/Sponsor",
      },
      token: cookies.t,
    });
    let result = [];
    res.forEach((item) => {
      if (item !== null) {
        result.push({ value: item, label: item });
      }
    });
    setTagData(result);
  };

  useEffect(() => {
    fetchTags();
  }, []);

  // modal tab changing ------------------
  const onCloseButton = () => {
    setIsModalOpen(false);
  };

  const onTabChange = (event, value) => {
    setActiveTab(value);
  };

  const _getComponent = (tabs) => {
    return dataArray
      .filter((item) => item.label === tabs)
      .map((item) => item.view)[0];
  };

  let dataArray = [
    {
      id: 0,
      label: "overview",
      view: (
        <OverView
          details={productAddressData?.[0]?.overview}
          company_id={productAddressData?.[0]?.company_id}
        />
      ),
    },
    {
      id: 1,
      label: "Products",
      view: <Product company_id={productAddressData?.[0]?.company_id} />,
    },
  ];

  // -------------------------------------

  // filtered location data --------------
  const filteredLocationData = productCompanyList?.data?.map((item) => {
    if (filters?.["Country"]?.length > 0) {
      const address = item?.address?.filter(
        (data) =>
          filters?.["Country"].includes(data?.country) &&
          filters?.["State"].includes(data?.state_region) &&
          filters?.["City"].includes(data?.city)
      );
      return {
        ...item,
        address: address,
      };
    } else {
      return {
        ...item,
      };
    }
  });

  // -------------------------------------

  // Search -------------------------------
  const handleSearch = (value) => {
    setSearchvalue(value);
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  };
  // --------------------------------------

  // HandleFilters ------------------------
  const handleFilterChange = (values) => {
    if (Object.keys(values)?.length > 0) {
      const modifiedData = Object.keys(values).reduce((acc, key) => {
        const newKey = key;
        acc[newKey] = values[key];
        return acc;
      }, {});

      // Check if the new modifiedData is different from the existing filters
      if (!areObjectsEqual(modifiedData, filters)) {
        if (currentPage !== 1) setCurrentPage(1);
        setFilters(modifiedData);
      }
    } else {
      setFilters({});
    }
  };
  // --------------------------------------

  // clicking address ---------------------
  const handleClickAdress = (clickAddress) => {
    if (locationData?.country !== (null || undefined)) {
      clickAddress.address = clickAddress?.address?.filter(
        (item) => item?.country === locationData?.country
      );
      setProductAddressData([clickAddress]);
    } else {
      setProductAddressData([clickAddress]);
    }

    if (userInfo?.walkin_trial && userInfo?.role == 'Manager/Sponsor') {
      updateCreditInfo({
        type: "company",
        id: clickAddress?.company_id,
        navigationType: "modal",
        modalFunction: setIsModalOpen,
      });
    } else {
      setIsModalOpen(true);
    }
  };

  // --------------------------------------

  // handle pagination ---------------------

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  const handleResetAll = () => {
    setAum(() => ({
      min: null,
      max: null,
    }));
    setResetAll(!resetAll);
  };

  // ---------------------------------------

  const handleCompanyView = (item) => {
    navigate(`/dashboard/company/${item}`, {
      state: {
        previousPage: location.pathname.endsWith("/Roadshow")
          ? location.pathname
          : `${location.pathname}/Roadshow`,
        previousName: `Fund Directory`,
        filters: {
          filters,
          aum,
          direactDealFilter,
          tagFilter,
          createdFilter,
          updatedFilter,
          fundingGoal,
          locationFilter,
          currentPage,
          itemsPerPage
        },
      },
    });
  };

  return (
    <>
      {screenWidth < 900 ? (
        <EditBlock />
      ) : (
        <>
          <div className={style.roadshowContainer}>
            <div className="ms-3 d-flex justify-content-between me-3">
              <div className="h1-i">Roadshow</div>
              <Search variant="search_md" onSearchEmit={handleSearch} />
            </div>
            <div className={`${style.roadshowHeader} mt-3`}>
              {/* <FilterComponent
          onFilterChange={handleFilterChange}
          type="productRoadshow"
          headingMobile={false} // Set to true if you want to hide the "Filters" button text on mobile
        /> */}
              <div
                className={`d-flex gap-2 ${
                  !filters_accessible ? "filters-not-allowed" : ""
                }`}
                style={{ flexWrap: "wrap" }}
              >
                <Funds
                  onFilterChange={(values) => {
                    setFilters(values);
                  }}
                  type="product"
                  headingtext="Funds"
                  headingMobile={false} // Set to true if you want to hide the "Filters" button text on mobile
                  disableKeysUntilCondition={true} // Pass the disableKeysUntilCondition object
                  reset={resetAll}
                  id="more_Filters"
                  isDateRange
                  dateRangeCount={1}
                  dateRangeNames={["AUM($mm)"]}
                  handleAum={(data) => {
                    setAum(data);
                  }}
                  selectedFilters={filters}
                  selectedAum={aum}
                  // className={style.reset_responsive_Mobile_btn}
                />
                <DireactDeal
                  type="product"
                  headingtext="Direct Deals"
                  id="direact_deals"
                  headingMobile={false} // Set to true if you want to hide the "Filters" button text on mobile
                  disableKeysUntilCondition={true}
                  isDateRange
                  dateRangeCount={1}
                  dateRangeNames={["Funding Goal($mm)"]}
                  onFilterChange={(values) => {
                    setDireactDealsFilter(values);
                  }}
                  handleAum={(data) => {
                    setFundingGoal(data);
                  }}
                  selectedFilters={direactDealFilter}
                  selectedAum={fundingGoal}
                  reset={resetAll}
                />
                <Location
                  onFilterChange={(values) => {
                    setLocationFilter(values);
                  }}
                  type="product"
                  headingtext="Location"
                  headingMobile={false}
                  disableKeysUntilCondition={true}
                  reset={resetAll}
                  id={"location_filter"}
                  selectedFilters={locationFilter}
                />

                <CreatedInvestorFilter
                  handleFilter={(data) => setCreatedFilter(data)}
                  filterOpenCreated={filterOpenCreated}
                  setFilterOpenCreated={setFilterOpenCreated}
                  id="createdDropdown"
                  // dropdownPosition={dropdownPositions.created}
                  type="small"
                  reset={resetAll}
                  selectedData={createdFilter}
                />
                <UpdateInvestorFilter
                  handleFilter={(data) => setUpdatedFilter(data)}
                  filterOpenUpdated={filterOpenUpdated}
                  setFilterOpenUpdated={setFilterOpenUpdated}
                  id="updatedDropdown"
                  // dropdownPosition={dropdownPositions.updated}
                  type="small"
                  reset={resetAll}
                  selectedData={updatedFilter}
                />
                <MultiselectFilter
                  title="Tags"
                  icon={<ArrowDown />}
                  data={tagData}
                  handleFilter={(filter) => {
                    setTagFilter(filter);
                    setResetAll(false);
                  }}
                  open={filterOpenTags}
                  setOpen={setFilterOpenTags}
                  id="tagsDropdown"
                  // dropdownPosition={dropdownPositions.tags}
                  sizeType="small"
                  reset={resetAll}
                  info={true}
                  selectedData={tagFilter}
                  information={"Based on added Tags"}
                />
                <ResetBtn onReset={() => handleResetAll()} type="small" />
              </div>
            </div>

            <div className={style.roadshowMap}>
              <GoogleMapCustom
                InvestorList={productAddressData}
                markerlatlan={markerlatlan}
                setmarkerlatlan={setmarkerlatlan}
              />

              <div className={style.dataDiv}>
                <>
                  {productCompanyList?.data?.length > 0 ? (
                    <>
                      {filteredLocationData?.map((item) => {
                        return (
                          <div
                            className={
                              item?.company_id === productCompanyId
                                ? style.InvestorListWrapClicked
                                : style.investorListWrap
                            }
                            onClick={() => {
                              handleClickAdress(item);
                              setProductCompanyId(item?.company_id);
                            }}
                          >
                            <h6 className={style.companyName}>{item?.name}</h6>
                            <div className={style.companyType}>
                              {item?.type}
                            </div>
                            {item?.address?.length > 0 && (
                              <div className={style.companyType}>
                                {locationData?.country !==
                                (null || undefined) ? (
                                  <>
                                    {item?.address?.[0]?.city +
                                      ", " +
                                      item?.address?.[0]?.state_region +
                                      ", " +
                                      item?.address?.[0]?.country}
                                  </>
                                ) : (
                                  <>
                                    {item?.address?.[0]?.city +
                                      ", " +
                                      item?.address?.[0]?.state_region +
                                      ", " +
                                      item?.address?.[0]?.country}
                                  </>
                                )}
                              </div>
                            )}
                            <div className={style.ItemDatesInfo}>
                              {item?.created_date && (
                                <div className={style.formattedDate}>
                                  Added on{" "}
                                  {moment
                                    .utc(item?.created_date)
                                    .format("MMM D, YYYY")}
                                </div>
                              )}
                              {item?.updated_date && (
                                <li className={style.formattedDate}>
                                  Last updated on{" "}
                                  {moment
                                    .utc(item?.updated_date)
                                    .format("MMM D, YYYY")}
                                </li>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : isDataThere ? (
                    <></>
                  ) : (
                    <div style={{ scale: "0.7" }} className="mt-4">
                      <NoContentCard title="No Data Found" />
                    </div>
                  )}
                </>

                {productCompanyList?.total_record > 5 && (
                  <div className="mt-2" onClick={(e) => handleUserClick(e)}>
                    <Pagination
                      itemsPerPage={itemsPerPage}
                      totalItems={productCompanyList?.total_record}
                      currentPage={currentPage}
                      isShowResult={false}
                      onPageChange={handlePageChange}
                      onItemsPerPageChange={handleItemsPerPageChange}
                      disabled={!filters_accessible}
                    />
                  </div>
                )}
              </div>
              {isModalOpen && (
                <>
                  <div className={style.googleGlobalWrap}>
                    <div className={style.googleGlobalInnerWrap}>
                      <div className={style.imgcloseWrap}>
                        <img
                          src={productAddressData?.[0]?.cover_photo || Default}
                          alt="company_image"
                        />

                        <CloseButton
                          className="close-icon-pop-up"
                          onClose={onCloseButton}
                          style={{ position: "absolute" }}
                        />

                        <img
                          className={style.LogoRoadMap}
                          src={
                            productAddressData?.[0]?.profile_photo || Default1
                          }
                          alt="roadshow"
                        ></img>

                        <div
                          className={style.viewCta}
                          onClick={(e) => {
                            isSubscriptionExpired
                              ? handleUserClick(e)
                              : handleCompanyView(
                                  productAddressData?.[0]?.company_id
                                );

                            // window.open(
                            //     `/dashboard/company/${productAddressData?.[0]?.company_id}`,
                            //     "_blank"
                            //   );
                          }}
                        >
                          View
                        </div>
                      </div>
                      <div style={{ paddingLeft: "10px", marginTop: "40px" }}>
                        <div className={style.companyHeaderRoad}>
                          <div className={style.companyNamepopup}>
                            {productAddressData?.[0]?.name}
                          </div>
                        </div>
                        <div className={style.companyHeaderRoad}>
                          {productAddressData?.[0]?.address?.length > 0 && (
                            <div
                              className={style.companyType}
                              style={{ paddingBottom: "0" }}
                            >
                              {productAddressData?.[0]?.address?.[0]
                                ?.state_region +
                                ", " +
                                productAddressData?.[0]?.address?.[0]?.country}
                            </div>
                          )}
                          <TypeDiv data="Company" color="#CABDFF" />
                        </div>
                      </div>
                      <div
                        style={{ paddingLeft: "20px", paddingRight: "20px" }}
                      >
                        <TabStrip
                          activeTab={activeTab}
                          onTabChange={onTabChange}
                          data={dataArray}
                          allowedTabId="0"
                          margin="1rem"
                          type="small"
                        />
                      </div>

                      <Box sx={{ width: "100%" }}>
                        <Box sx={{ padding: "0 20px", pt: 2 }}>
                          {_getComponent(activeTab)}
                        </Box>
                      </Box>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Roadshow;
